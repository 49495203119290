const UIObject = require("./UIObject");
const UIEvent = require("./UIEvent");
const UIEventBus = require("./UIEventBus");

const NetworkDevice = require("./NetworkDevice");

class Port extends UIObject {
  constructor(networkDevice, portNumber) {
    super();
    this.port = null;
    this.device = networkDevice;
    this.portNumber = portNumber;
  }

  isConnected() {
    return this.port == null ? false : true;
  }

  connect(port) {
    if (this.port != port) {
      console.log(
        "%s: Connecting Port %s to %s Port %s",
        this.device.mac,
        this.portNumber,
        port.device.mac,
        port.portNumber
      );
      if (this.isConnected()) {
        this.disconnect();
      }
      this.port = port;
    }

    if (port.port != this) {
      if (port.isConnected()) {
        port.disconnect();
      }
      port.connect(this);
      UIEventBus.Emit(
        new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_CONNECT, [], {
          message: this,
        })
      );
    }
  }

  disconnect() {
    if (!this.isConnected()) {
      console.log(
        "%s: Port %s was never connected.",
        this.device.mac,
        this.portNumber
      );
      return;
    }
    console.log(
      "%s: Disconnecting Port %s from %s Port %s",
      this.device.mac,
      this.portNumber,
      this.port.device.mac,
      this.port.portNumber
    );
    var port = this.port;
    this.port = null;
    if (port.isConnected()) {
      port.disconnect();
    }
  }

  propagatePacket(sender, packet) {
    if (sender instanceof Port) {
      UIEventBus.Emit(
        new UIEvent.E(this.UUID, UIEvent.UI_PACKET_MOVE, [1, 2], {
          packet: packet.UUID,
        })
      );
      this.device.receivePacket(this, packet);
    } else if (sender instanceof NetworkDevice.constructor) {
      UIEventBus.Emit(
        new UIEvent.E(this.UUID, UIEvent.UI_PACKET_MOVE, [1, 2], {
          packet: packet.UUID,
        })
      );
      this.port.propagatePacket(this, packet);
    }
  }
}
module.exports = Port;
