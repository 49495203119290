<template>
  <div class="MonitorView_container">
    <p style="color: gray">Konsole</p>
    <div class="monitor_content">
      <p v-for="m in logMessages" :key="m">
        <span>[{{ m.timestamp }}]</span>
        {{ m.msg }}
      </p>
    </div>
  </div>
</template>

<script>
  import UIEventBus from "@/networktracer/types/UIEventBus";
  import UIEvent from "@/networktracer/types/UIEvent";
  export default {
    name: "MonitorView",
    data() {
      return {
        logMessages: [],
      };
    },
    methods: {
      receiveLog(e) {
        console.log("Recieved LOG:", e);
        let msg = "";
        if (e.type == UIEvent.UI_DEVICE_CONNECT) {
          msg = "Zwei Geräte wurden verbunden.";
        }
        this.logMessages.push({
          msg: msg,
          timestamp: this.getCurrentTimeString(),
        });
      },
      getCurrentTimeString() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
      },
    },
    mounted() {
      UIEventBus.connect(this.receiveLog);
    },
  };
</script>

<style>
  .monitor_content {
    height: 100px;

    overflow-y: auto;
    border-top: 1px solid rgba(128, 128, 128, 0.342);
  }
  .monitor_content p {
    padding: 0;
    margin: 0;
  }
  .MonitorView_container {
    padding-left: 250px;
    /* border: 1px solid rgb(54, 54, 54); */
    position: fixed;
    background-color: rgb(248, 248, 248);
    bottom: 0px;

    height: 150px;

    width: 100%;
    /* z-index: 9999999; */
    transition: all 0.5s ease;
  }
</style>
