const UIEvent = require("./UIEvent");

class UIEventBus {
  listenerFunc = null;
  static Emit(event) {
    this.next = event.next;
    switch (event.type) {
      case UIEvent.UI_PACKET_CREATE:
        console.log("Paket wurde erzeugt");
        break;
      case UIEvent.UI_PACKET_MOVE:
        console.log("Paket wurde Bewegt");
        break;
      case UIEvent.UI_DEVICE_RECEIVE:
        console.log("Nachricht wurde von Gerät erhalten");
        console.log(event.content);
        break;
      case UIEvent.UI_DEVICE_SEND:
        console.log("Nachricht wurde von Gerät versendet");
        break;
      case UIEvent.UI_DEVICE_SIGN:
        console.log("Nachricht wurde signiert");
        break;
      case UIEvent.UI_DEVICE_ENCRYPT:
        console.log("Nachricht wurde verschlüsselt");
        break;
      case UIEvent.UI_DEVICE_DECRYPT:
        console.log("Nachricht wurde erfolgreich entschlüsselt");
        break;
      case UIEvent.UI_DEVICE_DECRYPT_FAILED:
        console.log("Nachricht konnte nicht entschlüsselt werden");
        break;
      case UIEvent.UI_DEVICE_CONNECT:
        console.log("Geräte wurden verbunden.");
        break;
    }
    for (let layer of event.osiLayers) {
      console.log(layer);
    }
    console.log("___");
    if (this.listenerFunc != null) {
      this.listenerFunc(event);
    }
  }
  //This function is called when an Event is emitted
  static connect(func) {
    this.listenerFunc = func;
  }
}

module.exports = UIEventBus;
