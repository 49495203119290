const UIObject = require("./UIObject");
const UIEvent = require("./UIEvent");
const UIEventBus = require("./UIEventBus");

class DataPacket extends UIObject {
  constructor(contentType, payload) {
    super();
    this.sourceIP;
    this.destIP;
    this.destMac;
    this.sourceMac;
    this.contentType = contentType;
    this.payload = payload;

    UIEventBus.Emit(new UIEvent.E(
      this.UUID,
      UIEvent.UI_PACKET_CREATE,
      [],
      {
        contentType: this.contentType,
        payload: this.payload,
      },
    ));
  }
}

module.exports = DataPacket;