<template>
  <div>
    <DropdownElement
      :options="availableDevices"
      class="ml-2"
      placeholder="Von IP"
      style="width: 100%"
      id="von"
    ></DropdownElement>
    <br /><br />
    <DropdownElement
      class="ml-2"
      placeholder="Zu IP"
      style="width: 100%"
      id="zu"
    ></DropdownElement>
    <br /><br />
    <InputText
      class="ml-2"
      placeholder="Nachricht zur Übermittlung"
      style="width: 100%"
      id="in_msg"
    ></InputText>
    <div class="col-6">
      <ButtonElement label="Absenden"></ButtonElement>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ComposeMessage",
    data() {
      return {
        availableDevices: [],
        von: null,
        zu: null,
        messageToSend: "",
      };
    },
    methods: {
      cleanDevicesForDisplay() {},
      getAvailableDevices() {
        console.log(this.$store.getters.getDevices);
        return [];
      },
    },
    mounted() {
      this.availableDevices = this.getAvailableDevices();
    },
  };
</script>
