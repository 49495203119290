import { createStore } from "vuex";

export default createStore({
	state: {
		connections: [],
		devices: [],
	},
	getters: {
		getConnections(state) {
			return state.connections;
		},
		getDevices(state) {
			return state.devices;
		},
	},
	mutations: {
		// Mutations for connections
		ADD_CONNECTION(state, connection) {
			state.connections.push(connection);
		},
		SET_CONNECTIONS(state, connections) {
			state.connections = connections;
		},
		REMOVE_CONNECTION(state, connectionId) {
			const index = state.connections.findIndex((connection) => connection.id === connectionId);
			if (index !== -1) {
				state.connections.splice(index, 1);
			}
		},
		REMOVE_ALL_CONNECTIONS(state) {
			state.connections = [];
		},
		// Mutations for devices
		ADD_DEVICE(state, device) {
			state.devices.push(device);
		},
		SET_DEVICES(state, devices) {
			state.devices = devices;
		},
		REMOVE_DEVICE(state, deviceId) {
			const index = state.devices.findIndex((device) => device.UUID === deviceId);
			if (index !== -1) {
				state.devices.splice(index, 1);
			}
		},
		UPDATE_DEVICE(state, { deviceId, newDevice }) {
			const index = state.devices.findIndex((device) => device.UUID === deviceId);

			if (index !== -1) {
				// Update the device with new data
				state.devices[index] = { ...state.devices[index], ...newDevice };
			}
		},
	},
	actions: {
		// Actions for connections
		addConnection({ commit }, connection) {
			commit("ADD_CONNECTION", connection);
		},
		removeConnection({ commit }, connectionId) {
			commit("REMOVE_CONNECTION", connectionId);
		},
		removeAllConnections({ commit }) {
			commit("REMOVE_ALL_CONNECTIONS");
		},
		setConnections({ commit }, connections) {
			commit("SET_CONNECTIONS", connections);
		},
		// Actions for devices
		addDevice({ commit }, device) {
			commit("ADD_DEVICE", device);
		},
		removeDevice({ commit }, deviceId) {
			commit("REMOVE_DEVICE", deviceId);
		},
		setDevices({ commit }, devices) {
			commit("SET_DEVICES", devices);
		},
		updateDevice({ commit }, { deviceId, newDevice }) {
			commit("UPDATE_DEVICE", { deviceId, newDevice });
		},
	},
	modules: {},
});
