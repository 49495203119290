<template>
  <div id="mySidebar" class="sidebar bg-light" :class="{ collapsed: !isOpen }">
    <div class="collapse-icon" @click="toggleCollapse">
      <i
        :class="this.isCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-left'"
      ></i>
    </div>
    <br />
    <br />
    <div class="cancel-message" id="cancelMessage" v-if="isConnecting">
      Drücke [ESC] zum Abbrechen
    </div>
    <div class="category">
      <h3>Elemente</h3>
      <div class="bauteil_box grid gap-1 g-0">
        <div class="bauteil" @dragend="handleDrop($event, 'Laptop')">
          <img
            src="@/assets/icons/laptop.png"
            id="selectorBauteil_laptop"
            alt=""
          />
        </div>
        <div class="bauteil" @dragend="handleDrop($event, 'Switch')">
          <img
            src="@/assets/icons/switch.png"
            id="selectorBauteil_switch"
            alt=""
          />
        </div>
        <div class="bauteil" @dragend="handleDrop($event, 'PC')">
          <img src="@/assets/icons/pc.png" id="selectorBauteil_pc" alt="" />
        </div>
        <div class="bauteil" @dragend="handleDrop($event, 'Router')">
          <img
            src="@/assets/icons/router2.png"
            id="selectorBauteil_router"
            alt=""
          />
        </div>
        <div class="bauteil" @dragend="handleDrop($event, 'Server')">
          <img
            src="@/assets/icons/server2.png"
            id="selectorBauteil_server"
            alt=""
          />
        </div>
        <!-- <div class="bauteil" @dragend="handleDrop($event, 'Switch')">
					<img src="@/assets/icons/switch.png" id="bauteilLaptop_selector" alt="" />
				</div>
				<div class="bauteil" @dragend="handleDrop($event, 'Switch')">
					<img src="@/assets/icons/switch.png" id="bauteilLaptop_selector" alt="" />
				</div> -->
      </div>
    </div>

    <div class="category sticky-bottom">
      <h3>Aktionen</h3>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="importProject"
      />
      <a href="#" @click="importProject()">
        <i class="pi pi-file-import"></i><span>Projekt importieren</span>
      </a>
      <a href="#" @click="exportProject()">
        <i class="pi pi-file-export"></i><span>Projekt Exportieren</span>
      </a>
      <a href="#" exact @click="deleteAll()">
        <i class="pi pi-trash text-red-500"></i>
        <span class="text-red-500">Alles Leeren</span>
      </a>
    </div>
  </div>
  <ContextMenu :model="items" ref="menu"></ContextMenu>
  <canvas ref="lineCanvas" class="line-canvas"></canvas>
</template>

<style scoped>
  .cancel-message {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(185, 0, 0, 0.8); /* Red with some transparency */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Box shadow for depth */
    z-index: 9999; /* Ensure it's above other elements */
  }
  .sticky-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .bauteil > img {
    /* width: 50px; */
    max-height: 50px;
    max-width: 50px;
  }
  .bauteil {
    height: 50px;
    width: 50px;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 5px;
    text-align: center;
  }
  .bauteil:hover {
    background-color: #7cc4ff;
    border: 1px solid white;
    cursor: pointer;
  }
  .collapsed .bauteil_box {
    display: none;
  }
  .bauteil_box {
    /* border: 1px solid red; */
    /* height: 200px; */
    padding-left: 20px;
    padding-top: 10px;
    /* transition: all 0.25s; */
  }
  .collapsed .category {
    font-size: 0;
    /* border-bottom: 1px solid rgba(102, 102, 102, 0.397); */
  }
  .collapsed .category:last-child {
    font-size: 0;
    border-bottom: none;
  }

  .collapsed .category > h3 {
    font-size: 0;
    border-bottom: none;
  }

  .collapsed {
    text-align: center;
    width: 50px !important;
    overflow: hidden;
  }
  .collapsed span {
    display: none;
  }
  .collapse-icon {
    float: right;
    padding: 8px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    z-index: 10;
  }

  .collapse-icon i {
    font-size: 20px;
  }
  @media screen and (max-height: 450px) {
    .sidebar {
      display: none;
    }
  }
  .category > h3 {
    color: rgb(150, 150, 150);
    padding-left: 20px;
    font-size: 1rem;
  }
  .sidebar {
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    /* width: 0; */
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
  }

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #444444;
    display: block;
    transition: 0.3s;
    /* border-bottom: 1px solid #ccc; */
  }
  .sidebar.collapsed a {
    padding-left: 10px;
    padding-right: 0px;
  }

  .sidebar a i,
  .sidebar a img {
    margin-right: 8px;
  }

  .sidebar a:hover {
    /* color: #0449ca; */
    /* box-shadow: inset 4px 0 0 #95bbda; */
    background-color: #00000021;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }

  .openbtn:hover {
    background-color: #444;
  }

  #main {
    transition: margin-left 0.5s;
    padding: 16px;
  }
  .sidebar a.exact-active {
    box-shadow: inset 6px 0 0 #2196f3;
    background-color: #00000013;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
  }
  .line-canvas {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none; /* Allow mouse events to pass through the canvas */
    z-index: 999; /* Make sure the canvas is on top of other elements */
    image-rendering: pixelated; /* Prevent blurriness */
  }
</style>

<style>
  .clonedElement {
    max-height: 50px;
    max-width: 50px;
    cursor: pointer;
    transition: transform 0.1s ease;
  }
  .clonedElement:hover {
    transform: scale(1.1);
    /* border: 1px solid black;
  border-radius: 100%; */
    /* padding: 10px; */
  }
</style>
<script>
  import BauteilSettings from "./BauteilSettings.vue";
  import Device from "../networktracer/types/Device";
  import NetworkService from "../services/network";
  export default {
    emits: ["change"],

    data() {
      return {
        isCollapsed: false,
        lineCanvasContext: null,
        allowedDrop: false,
        isConnecting: false,
        selectedBauteil: null,
        nt_devices: [],
        connections: [],

        drawing: false,
        startPosition: { x: 0, y: 0 },
        items: [
          {
            label: "Einstellungen",
            icon: "pi pi-cog",
            command: () => {
              this.$dialog.open(BauteilSettings, {
                data: {
                  bauteil: this.selectedBauteil,
                  test: "dbg",
                },
                props: {
                  modal: true,
                  header: "Element bearbeiten",
                  style: {
                    width: "50vw",
                  },
                  breakpoints: {
                    "960px": "75vw",
                    "640px": "90vw",
                  },
                },
              });
            },
          },
          {
            label: "Verbinden",
            icon: "pi pi-arrow-right-arrow-left",
            command: () => {
              this.isConnecting = true;

              //console.log("Connecting:", this.isConnecting, this, this.selectedBauteil);
            },
          },
          {
            label: "Löschen",
            icon: "pi pi-trash",
            command: () => {
              //let { type } = this.selectedBauteil;

              let { id } = this.selectedBauteil["data-bauteil"];

              this.selectedBauteil = null;
              document.getElementById("element-" + id).remove();
              document.getElementById("label-" + id).remove();

              this.connections = this.connections.filter((con) => {
                const isSourceEqual = con.source.id === `element-${id}`;
                const isTargetEqual = con.target.id === `element-${id}`;

                // Check if either source or target is not equal to the selectedBauteil ID
                if (!(isSourceEqual || isTargetEqual)) {
                  // console.log("Removed:", con);
                  return con;
                }
              });

              this.$store.dispatch("removeDevice", id);
              this.$store.dispatch("setConnections", this.connections);

              this.renderConnections();
            },
          },
        ],
      };
    },
    watch: {
      connections: {
        handler() {
          this.renderConnections();
        },
        deep: true,
      },
    },

    methods: {
      removeDuplicates(arr) {
        const seen = {};
        const uniqueArray = [];

        arr.forEach((obj) => {
          const serializedObj = JSON.stringify(obj);
          if (!seen[serializedObj]) {
            seen[serializedObj] = true;
            uniqueArray.push(obj);
          }
        });

        return uniqueArray;
      },
      checkConnections() {
        let conStore = this.$store.getters.getConnections;
        conStore = this.removeDuplicates(conStore);
        this.$store.dispatch("setConnections", conStore);
      },
      exportProject() {
        let _devices = this.$store.getters.getDevices;
        //Deleting myKeyPair and ports from the device object in the export, in order to temporarily fix the circular object bug
        _devices = _devices.map((d) => {
          delete d.myKeyPair;
          delete d.ports;
          return d;
        });
        const dataToExport = {
          connections: this.$store.getters.getConnections,
          devices: _devices,
        };

        //console.log(dataToExport);

        const jsonData = JSON.stringify(dataToExport, null, 2);

        // Create a Blob containing the JSON data
        const blob = new Blob([jsonData], { type: "application/json" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = "NetworktracerProject.json";

        // Simulate a click to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      buildProjectFromStore() {
        let devices = this.$store.getters.getDevices;
        //let connections = this.$store.getters.getDevices;

        devices.forEach((d) => {
          var mainDiv = document.getElementById("mainAllJ");

          var x = d.location.x;
          var y = d.location.y;

          let tempName =
            d.name == ""
              ? d.type + " " + Math.floor(Math.random() * 10)
              : d.name;
          let element = document.getElementById(
            "selectorBauteil_" + d.type.toLowerCase()
          );
          //let element = document.getElementById("selectorBauteil_laptop");
          let id = d.UUID;

          let clone = element.cloneNode(true);
          clone.classList.add("clonedElement");
          clone.style.position = "absolute";
          clone.style.left = x - 10 + "px";
          clone.style.top = y - 10 + "px";
          clone["data-bauteil"] = {
            type: d.type,
            id: id,
            bezeichnung: tempName,
            ip_address: d.ip,
          };
          clone.onclick = this.handleClick;
          clone.id = "element-" + id;
          clone.ondragend = this.handleDropMove;
          clone.oncontextmenu = this.onRightClick;
          mainDiv.appendChild(clone);
          //console.log(clone);
          //label---
          let labelDiv = document.createElement("div");
          labelDiv.classList.add("label");
          labelDiv.innerHTML = tempName;
          mainDiv.appendChild(labelDiv);
          labelDiv.id = "label-" + id;
          labelDiv.style.position = "absolute";
          labelDiv.style.left = x - 15 + "px";
          labelDiv.style.top = y + 30 + "px";
        });
        let _connections = this.$store.getters.getConnections;
        _connections.forEach((c) => {
          c.source = document.getElementById(
            `element-${c.source["data-bauteil"].id}`
          );
          c.target = document.getElementById(
            `element-${c.target["data-bauteil"].id}`
          );
        });
        this.connections = _connections;
        this.renderConnections();
      },
      async importProject(event) {
        if (!event) return this.$refs.fileInput.click();
        const file = event.target.files[0];
        if (!file) return;
        try {
          const jsonData = await this.readFile(file);
          const projectData = JSON.parse(jsonData);

          // update vuex store
          this.$store.commit("SET_CONNECTIONS", projectData.connections);
          this.$store.commit("SET_DEVICES", projectData.devices);

          console.log("Project imported successfully.");
          this.buildProjectFromStore();
        } catch (error) {
          console.error("Error importing project:", error);
        }
      },
      readFile(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
        });
      },
      renderConnections() {
        const canvas = this.$refs.lineCanvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Render connections
        this.connections.forEach((connection) => {
          let start = {
            x: connection.source.offsetLeft + connection.source.offsetWidth / 2,
            y: connection.source.offsetTop + connection.source.offsetHeight / 2,
          };
          let end = {
            x: connection.target.offsetLeft + connection.target.offsetWidth / 2,
            y: connection.target.offsetTop + connection.target.offsetHeight / 2,
          };

          ctx.beginPath();
          ctx.moveTo(start.x, start.y);
          ctx.lineTo(end.x, end.y);
          ctx.stroke();
        });
      },
      onRightClick(event) {
        this.selectedBauteil = event.target;
        this.$refs.menu.show(event);
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
        this.$emit("change", this.isCollapsed);

        // this.$store.dispatch("setSidenavState", {
        //   key: "open",
        //   value: this.isCollapsed,
        // });
      },

      handleClick(event) {
        if (this.isConnecting && this.selectedBauteil != event.target) {
          this.isConnecting = false;

          //data-bauteil[connection] zu einem array machen, und pushen, und überprüfen ob UUID schon in connections, sonst return

          // let nt_source = this.nt_devices.filter(
          //   (x) => x.UUID == _selectedBauteil.id
          // )[0];
          // let nt_target = this.nt_devices.filter((x) => x.UUID == _target.id)[0];
          // nt_source.getPort(0).connect(nt_target.getPort(0));

          let _con = {
            source: this.selectedBauteil,
            target: event.target,
          };
          let isAlreadyConnected = false;

          this.connections.forEach((c) => {
            if (
              (c.source["data-bauteil"].id == _con.source["data-bauteil"].id &&
                c.target["data-bauteil"].id ==
                  event.target["data-bauteil"].id) ||
              (c.target["data-bauteil"].id == _con.source["data-bauteil"].id &&
                c.source["data-bauteil"].id == event.target["data-bauteil"].id)
            ) {
              isAlreadyConnected = true;
            }
          });
          if (isAlreadyConnected) {
            this.renderConnections();
            return console.log("Already connected!");
          }

          let _selectedBauteil = window.structuredClone(
            this.selectedBauteil["data-bauteil"]
          );
          let _target = window.structuredClone(event.target["data-bauteil"]);

          //console.log(_selectedBauteil, _target);

          event.target["data-bauteil"]["connection"] = _selectedBauteil;
          this.selectedBauteil["data-bauteil"]["connection"] = _target;

          //console.log(_con);

          this.connections.push(_con);

          NetworkService.connectDevices(_selectedBauteil.id, _target.id);
          this.$store.dispatch("addConnection", _con);
          this.checkConnections();
        }
      },
      handleDropMove(event) {
        if (!this.allowedDrop) return;
        var element = event.target;
        let { id } = element["data-bauteil"];
        var mainDiv = document.getElementById("mainAllJ");
        var rect = mainDiv.getBoundingClientRect();
        var x = event.clientX - rect.left;
        var y = event.clientY - rect.top;
        element.style.left = x - 10 + "px";
        element.style.top = y - 10 + "px";
        //label--
        let labelDiv = document.getElementById("label-" + id);
        labelDiv.style.left = x - 15 + "px";
        labelDiv.style.top = y + 30 + "px";
        //--label
        //console.log(element.style.left);
        //let _x = element.style.left.replace("px", "");
        //console.log("X:", _x);
        this.$store.dispatch("updateDevice", {
          deviceId: id,
          newDevice: {
            location: {
              x: parseInt(element.style.left.replace("px", "")),
              y: parseInt(element.style.top.replace("px", "")),
            },
          },
        });
        //handle visual connections
        //console.log(this.connections);
        //let con = this.$store.getters.getConnections;
        //console.log(con);
        this.renderConnections();
      },
      handleDrop(event, type) {
        // eslint-disable-next-line
        event = $.event.fix(event);
        //console.log(event);
        if (!this.allowedDrop) return;
        var element = event.target;
        var mainDiv = document.getElementById("mainAllJ");

        // Adjust for scroll position
        var x = event.clientX + window.scrollX;
        var y = event.clientY + window.scrollY;

        let tempName = type + " " + Math.floor(Math.random() * 10);

        let nt_device = new Device("1.1.1.1");
        nt_device.name = tempName;
        this.nt_devices.push(nt_device);
        NetworkService.addDevice(nt_device);
        this.$store.dispatch("addDevice", nt_device);

        //console.log(nt_device);
        let id = nt_device.UUID;

        let clone = element.cloneNode(true);
        clone.classList.add("clonedElement");
        clone.style.position = "absolute";
        clone.style.left = x - 10 + "px";
        clone.style.top = y - 10 + "px";
        clone["data-bauteil"] = {
          type: type,
          id: id,
          bezeichnung: tempName,
          ip_address: "1.1.1.1",
        };
        clone.onclick = this.handleClick;
        clone.id = "element-" + id;
        clone.ondragend = this.handleDropMove;
        clone.oncontextmenu = this.onRightClick;
        mainDiv.appendChild(clone);

        //console.log(element.style.left, element);
        //let _x = element.style.left.replace("px", "");
        //console.log("X:", _x, x, y);
        this.$store.dispatch("updateDevice", {
          deviceId: id,
          newDevice: { location: { x: x - 10, y: y - 10 }, type: type },
        });

        //label---
        let labelDiv = document.createElement("div");
        labelDiv.classList.add("label");
        labelDiv.innerHTML = tempName;
        mainDiv.appendChild(labelDiv);
        labelDiv.id = "label-" + id;
        labelDiv.style.position = "absolute";
        labelDiv.style.left = x - 15 + "px";
        labelDiv.style.top = y + 30 + "px";
        //--label
      },
      deleteAll() {
        //this.$refs.lineCanvas.clear;
        this.connections = [];
        document.querySelectorAll(".clonedElement").forEach((e) => {
          e.remove();
        });
        document.querySelectorAll(".label").forEach((e) => {
          e.remove();
        });
        this.$store.dispatch("setConnections", []);
        this.$store.dispatch("setDevices", []);
      },
      openNav() {
        document.getElementById("mySidebar").style.width = "250px";
        //document.getElementById("main").style.marginLeft = "250px";
      },
    },
    mounted() {
      //setTimeout(this.openNav(), 500);
      this.openNav();
      this.$refs.lineCanvas.width = window.innerWidth;
      this.$refs.lineCanvas.height = window.innerHeight;
      this.lineCanvasContext = this.$refs.lineCanvas.getContext("2d");

      document.addEventListener("dragover", (event) => {
        //console.log(event);+

        if (event.target.id === "mainAllJ") {
          this.allowedDrop = true;
          event.preventDefault();
        } else {
          this.allowedDrop = false;
        }
      });
      document.addEventListener("mousemove", (event) => {
        if (!this.isConnecting || !this.selectedBauteil) return;

        //Clear the previous line
        this.lineCanvasContext.clearRect(
          0,
          0,
          this.$refs.lineCanvas.width,
          this.$refs.lineCanvas.height
        );
        this.renderConnections(); //make previous lines stay visible
        // Draw a line from the sourceElement to the current mouse position
        this.lineCanvasContext.beginPath();
        this.lineCanvasContext.moveTo(
          this.selectedBauteil.offsetLeft +
            this.selectedBauteil.offsetWidth / 2,
          this.selectedBauteil.offsetTop + this.selectedBauteil.offsetHeight / 2
        );
        this.lineCanvasContext.lineTo(event.clientX, event.clientY);
        this.lineCanvasContext.stroke();
      });
      document.addEventListener("keydown", (event) => {
        if (event.key == "Escape") {
          if (this.isConnecting) {
            this.isConnecting = false;
            this.renderConnections();
          }
        }
      });
    },
    computed: {
      isOpen() {
        return !this.isCollapsed;
        //return this.$store.getters.getSidenav.open;
      },
    },
  };
</script>
