import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import { RouterLink } from "vue-router";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";

import Button from "primevue/button";
import ContextMenu from "primevue/contextmenu";

import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import store from "./store";
import Dropdown from "primevue/dropdown";

const app = createApp(App).use(store);
app
  .use(PrimeVue)
  .use(DialogService)
  .use(ToastService)
  .component("ButtonElement", Button)
  .component("DynamicDialog", DynamicDialog)
  .component("ToastElement", Toast)
  .component("ContextMenu", ContextMenu)
  .component("router-link", RouterLink)
  .component("InputText", InputText)
  .component("DividerElement", Divider)
  .component("DropdownElement", Dropdown)
  //
  .mount("#app");
