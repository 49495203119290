//Notiz: Hallo Joel der Zukunft, um das Polyfill/Cant find Module problem zu lösen, bringt dich das in die richtige Richtung
// >>> https://stackoverflow.com/questions/64557638/how-to-polyfill-node-core-modules-in-webpack-5 <<<

//import Device from "@/networktracer/types/Device";

//import Device from "@/networktracer/types/Device";

//const Device = require("../networktracer/types/Device");
//const CertificateAuthority = require("../networktracer/types/CertificateAuthority");

const CreatedDevices = [];

//let CA = new CertificateAuthority("192.168.0.3");

//let device1 = new Device("192.168.0.1");
//let device2 = new Device("192.168.0.2");

function addDevice(device) {
  //let newDevice = new Device(device.ip);
  CreatedDevices.push(device);
}
function connectDevices(d1, d2) {
  d1 = CreatedDevices.find((d) => d.UUID == d1);
  d2 = CreatedDevices.find((d) => d.UUID == d2);
  d1.getPort(findFreePort(d1)).connect(d2.getPort(findFreePort(d2)));

  //new Device().getPort(0).isConnected()
}
function findFreePort(device) {
  for (let index = 0; index < 100; index++) {
    if (!device.getPort(index)) console.log(device);
    if (!device.getPort(index).isConnected()) {
      return index;
    }
  }
}

export default {
  addDevice,
  connectDevices,
};
// device1.getPort(0).connect(device2.getPort(0));

// Alle Geräte zu der CA verbinden

// device1.setCAPublicKey(CA.getPublicKey());
// device2.setCAPublicKey(CA.getPublicKey());

// device1.getPort(1).connect(CA.getPort(0));
// device2.getPort(1).connect(CA.getPort(1));

// device1.requestCertificate(1);
// device2.requestCertificate(1);

// device1.sendEncryptedMessage("Hallo", 0);
// device2.sendEncryptedMessage("Hallo", 0);

//device2.sendMessage('Hallo', 0);
