const UI_PACKET_CREATE = 0x01;
const UI_PACKET_MOVE = 0x02;
const UI_DEVICE_SIGN = 0x03;
const UI_DEVICE_ENCRYPT = 0x04;
const UI_DEVICE_DECRYPT = 0x05;
const UI_DEVICE_DECRYPT_FAILED = 0x06;
const UI_DEVICE_SEND = 0x07;
const UI_DEVICE_RECEIVE = 0x08;
const UI_DEVICE_CONNECT = 0x09;
class UIEvent {
  constructor(uuid, type, osiLayers, content) {
    this.uuid = uuid;
    this.type = type;
    this.osiLayers = osiLayers;
    this.content = content;
  }
}

module.exports = {
  UI_PACKET_CREATE: UI_PACKET_CREATE,
  UI_PACKET_MOVE: UI_PACKET_MOVE,
  UI_DEVICE_SIGN: UI_DEVICE_SIGN,
  UI_DEVICE_ENCRYPT: UI_DEVICE_ENCRYPT,
  UI_DEVICE_DECRYPT: UI_DEVICE_DECRYPT,
  UI_DEVICE_DECRYPT_FAILED: UI_DEVICE_DECRYPT_FAILED,
  UI_DEVICE_SEND: UI_DEVICE_SEND,
  UI_DEVICE_RECEIVE: UI_DEVICE_RECEIVE,
  UI_DEVICE_CONNECT: UI_DEVICE_CONNECT,
  E: UIEvent,
};
