<template>
  <div class="grid">
    <div class="col-6">
      <label for="in_id">ID</label>
      <InputText
        placeholder="ID"
        :value="bauteil.id"
        style="width: 100%"
        id="in_id"
        disabled
        readonly
      ></InputText>
    </div>
    <div class="col-6">
      <label for="in_type">Type</label>
      <!-- <img
        src="@/assets/icons/pc.png"
        style="width: 50px; height: 50px"
        alt=""
      /> -->
      <InputText
        placeholder="type"
        :value="bauteil.type"
        style="width: 100%"
        id="in_type"
        disabled
        readonly
      >
      </InputText>
    </div>
    <div class="col-6">
      <label for="in_bezeichnung">Bezeichnung</label>
      <InputText
        placeholder="bezeichnung"
        v-model="bauteil.bezeichnung"
        style="width: 100%"
        id="in_bezeichnung"
      ></InputText>
    </div>
    <div class="col-6">
      <label for="in_ip">IP-Adresse</label>
      <InputText
        v-model="bauteil.ip_address"
        placeholder="IP-Adresse"
        style="width: 100%"
        id="in_ip"
      ></InputText>
    </div>
    <!-- <div class="col-6">
      <ButtonElement
        label="Änderungen speichern"
        @click="saveChanges()"
      ></ButtonElement>
    </div> -->
    <DividerElement v-if="bauteil_connections.length != 0"></DividerElement>
    <p class="ml-2" v-if="bauteil_connections.length != 0">
      Aktuelle Verbindungen:&nbsp;
    </p>
    <!-- <p>{{ bauteil_connections }}</p> -->
    <div
      class="connectionEntry"
      v-for="con in bauteil_connections"
      :key="con.id"
    >
      <img
        :src="getDeviceIcon(con.type)"
        style="width: 50px; max-height: 65px"
        class="connectionEntryDeviceIcon"
      />
      <span class="chipLike">Typ: {{ con.type }}</span
      >&nbsp;<span class="chipLike">Bezeichnung: {{ con.bezeichnung }}</span
      >&nbsp;<span class="chipLike">IP: {{ con.ip_address }}</span>
    </div>
    <!-- <InputText class="ml-2" placeholder="Nachricht zur Übermittlung" style="width: 100%" id="in_msg"></InputText>
		<div class="col-6">
			<ButtonElement label="Absenden" :disabled="bauteil_connections.length == 0"></ButtonElement>
			
		</div> -->
  </div>
</template>
<style scoped>
  .chipLike {
    /* border: 1px solid rgb(233, 233, 233); */
    background-color: #007bff83;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
  }
  .connectionEntry {
    display: block;
    width: 100%;
  }
</style>
<script>
  export default {
    name: "BauteilSettings",
    inject: ["dialogRef"],
    data() {
      return {
        bauteil: {},
        bauteil_connections: [],
      };
    },
    mounted() {
      //console.log(this.dialogRef, this);
      this.bauteil = this.dialogRef.data.bauteil["data-bauteil"];
      this.getConnections();
    },
    beforeUnmount() {
      this.saveChanges();
    },
    methods: {
      getDeviceIcon(type) {
        switch (type) {
          case "Router":
            return require("@/assets/icons/router2.png");
          case "PC":
            return require("@/assets/icons/pc.png");
          case "Laptop":
            return require("@/assets/icons/laptop.png");
          case "Switch":
            return require("@/assets/icons/switch.png");
          case "Server":
            return require("@/assets/icons/server2.png");
          default:
            break;
        }
      },
      getConnections() {
        let _connections = this.$store.getters.getConnections;

        let connections = _connections.filter(
          (c) =>
            c["source"]["data-bauteil"].id == this.bauteil.id ||
            c["target"]["data-bauteil"].id == this.bauteil.id
        );
        connections = connections.map((c) => {
          c =
            c.source["data-bauteil"].id == this.bauteil.id
              ? c.target["data-bauteil"]
              : c.source["data-bauteil"];
          //delete c.connection;

          return c;
        });
        connections.forEach((c) => {
          delete c.connection;
        });
        console.log(connections);
        this.bauteil_connections = connections;
        //this.bauteil.connection = connections;
      },
      saveChanges() {
        //console.log("save changes called.");
        let element = document.getElementById("element-" + this.bauteil.id);
        element["data-bauteil"].bezeichnung = this.bauteil.bezeichnung;
        element["data-bauteil"].ip_address = this.bauteil.ip_address;
        document.getElementById("label-" + this.bauteil.id).innerHTML =
          this.bauteil.bezeichnung;
        this.$store.dispatch("updateDevice", {
          deviceId: this.bauteil.id,
          newDevice: {
            name: this.bauteil.bezeichnung,
            ip: this.bauteil.ip_address,
          },
        });
        // this.$toast.add({
        //   severity: "success",
        //   detail: "Änderungen gespeichert.",
        //   life: 1500,
        // });
      },
    },
  };
</script>
