const { 
    v1: uuidv1,
  } = require('uuid');

class UIObject {
    constructor() {
        this.UUID = uuidv1()
    }
}

module.exports = UIObject;