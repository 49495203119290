const UIEvent = require("./UIEvent");
const UIEventBus = require("./UIEventBus");

const NetworkDevice = require("./NetworkDevice");
const DataPacket = require("./DataPacket");
const NodeRSA = require("node-rsa");
/*eslint-disable*/
class Device extends NetworkDevice {
  constructor(ip) {
    super(
      ip,
      "XX:XX:XX:XX:XX:XX".replace(/X/g, function () {
        return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16));
      }),
      100
    );
    this.myKeyPair;
    this.caPublicKey = null;
    this.partnerPublicKey = null;
    this.certificate = null;
    this.createKeyPair();
  }
  setCAPublicKey(key) {
    this.caPublicKey = new NodeRSA(key);
  }
  signMessage(message) {
    return this.myKeyPair.sign(message);
  }
  signatureIsValid(key, signature) {
    return this.caPublicKey.verify(key, signature);
  }
  getPublicKey() {
    return this.myKeyPair.exportKey("pkcs1-public");
  }
  createKeyPair() {
    this.myKeyPair = new NodeRSA({ b: 512 });
  }
  encryptMessage(message, key) {
    let encrypted = key.encrypt(message);
    return encrypted;
  }
  decryptMessage(encryptedMessage) {
    try {
      var decryptedMessage = this.myKeyPair.decrypt(encryptedMessage);

      UIEventBus.Emit(
        new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_DECRYPT, [7, 6, 5], {
          message: decryptedMessage,
        })
      );
    } catch {
      UIEventBus.Emit(
        new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_DECRYPT_FAILED, [7, 6, 5], {
          message: null,
        })
      );

      console.log(
        "%s: Expected encrypted message, but decryption failed.",
        this.mac
      );
    }
    return decryptedMessage;
  }
  storePublicKey(publicKey) {
    this.partnerPublicKey = new NodeRSA(publicKey);
  }
  sendEncryptedMessage(message, portNumber) {
    //Public Key holen
    this.sendPacket(
      new DataPacket("REQUEST_PUBLIC_KEY", "NO_CONTENT"),
      portNumber
    );

    //Nachricht mit Public key verschlüsseln und senden
    let encrypted = this.encryptMessage(message, this.partnerPublicKey);

    UIEventBus.Emit(
      new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_ENCRYPT, [7, 6, 5], {
        message: encrypted,
      })
    );
    this.sendPacket(new DataPacket("ENCRYPTED_MESSAGE", encrypted), portNumber);
    this.partnerPublicKey = null;
  }

  requestCertificate(portNumber) {
    let encrypted = this.encryptMessage(this.getPublicKey(), this.caPublicKey);

    UIEventBus.Emit(
      new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_ENCRYPT, [7, 6, 5], {
        message: encrypted,
      })
    );
    this.sendPacket(
      new DataPacket("REQUEST_CERTIFICATE", encrypted),
      portNumber
    );
  }

  sendMessage(message, portNumber) {
    this.sendPacket(new DataPacket("MESSAGE", message), portNumber);
  }

  receivePacket(sender, packet) {
    super.receivePacket(sender, packet);

    switch (packet.contentType) {
      case "REQUEST_PUBLIC_KEY":
        let publicKeyPacket = new DataPacket(
          "PUBLIC_KEY",
          this.myKeyPair.exportKey("pkcs1-public")
        );
        this.sendPacket(publicKeyPacket, sender.portNumber);
        break;
      case "PUBLIC_KEY":
        this.storePublicKey(packet.payload);
        // Wenn wir einen Public Key einer CA kennen, können wir nach der Signatur fragen
        if (this.caPublicKey != null) {
          this.sendPacket(
            new DataPacket("REQUEST_SIGNATURE", "NO_CONTENT"),
            sender.portNumber
          );
        }
        break;
      case "REQUEST_SIGNATURE":
        if (this.certificate != null) {
          this.sendPacket(
            new DataPacket("SIGNATURE", this.certificate),
            sender.portNumber
          );
        }
        break;
      case "SIGNATURE":
        if (
          !this.caPublicKey.verify(
            this.partnerPublicKey.exportKey("pkcs1-public"),
            packet.payload
          )
        ) {
          console.log(
            "%s: The received public key was NOT signed by my CA",
            this.mac
          );
          this.partnerPublicKey = null;
        } else {
          console.log(
            "%s: The received public key was signed by my CA",
            this.mac
          );
        }
        break;
      case "CERTIFICATE":
        this.certificate = packet.payload;
        break;
      case "ENCRYPTED_MESSAGE":
        console.log(
          "%s: Encrypted message reads: %s",
          this.mac,
          this.decryptMessage(packet.payload)
        );
        break;
      case "MESSAGE":
        UIEventBus.Emit(
          new UIEvent.E(this.UUID, UIEvent.UI_DEVICE_RECEIVE, [7, 6, 5], {
            message: packet.payload,
          })
        );
      default:
        break;
    }
  }
}

module.exports = Device;
