const UIObject = require("./UIObject");
const UIEvent = require("./UIEvent");
const UIEventBus = require("./UIEventBus");

const Port = require("./Port");

class NetworkDevice extends UIObject {
  constructor(ip, mac, numberOfPorts) {
    super();
    this.ports = [];
    this.ip = ip;
    this.mac = mac;
    this.name = "";
    for (var i = 0; i <= numberOfPorts; i++) {
      this.ports.push(new Port(this, i));
    }
  }

  setName(name) {
    this.name = name;
  }

  getPort(portNumber) {
    return this.ports.at(portNumber);
  }

  sendPacket(packet, portNumber) {
    var selectedPort = this.ports.at(portNumber);

    if (selectedPort == undefined) {
      console.log("%s: Port %s does not exist.", this.mac, portNumber);
      return;
    }

    UIEventBus.Emit(new UIEvent.E(
      this.UUID,
      UIEvent.UI_DEVICE_SEND,
      [3, 4],
      {port: selectedPort.UUID}
    ));
    if (selectedPort.isConnected()) {
      console.log("%s: Sending packet via Port %s", this.mac, portNumber);
      selectedPort.propagatePacket(this, packet);
    } else {
      console.log(
        "%s: Could not send packet via Port %s, since it's not connected.",
        this.mac,
        portNumber
      );
      return;
    }
  }

  receivePacket(sender, packet) {

    UIEventBus.Emit(new UIEvent.E(
      this.UUID,
      UIEvent.UI_DEVICE_RECEIVE,
      [3, 4],
      {message: packet.payload}
    ));

    console.log(
      "%s: Received packet on Port %s with content %s",
      this.mac,
      sender.portNumber,
      packet.payload
    );
    /*if (this.mac != packet.destMac) {
      console.log(
        "%s: This packet was meant for %s, not me.",
        this.mac,
        packet.destMac
      );
    } else {
      console.log("%s: Packet received successfully.", this.mac);
    }*/
  }
}

module.exports = NetworkDevice;
