<template>
  <div>
    <DynamicDialog></DynamicDialog>
    <ToastElement></ToastElement>
    <SideNav @change="handleChange($event)"></SideNav>
    <div id="mainAllJ" class="dropzone"></div>
    <MonitorView id="MonitorView_Component"></MonitorView>
    <ButtonElement
      id="btn_compose"
      label="Nachricht Verfassen"
      icon="pi pi-send"
      @click="openComposeDialog()"
    ></ButtonElement>
    <!-- <span style="padding-left: 500px">{{ getClass }}</span> -->
  </div>
</template>

<script>
  //import TopNav from "./components/TopNav.vue";
  import SideNav from "./components/SideNav.vue";
  import MonitorView from "./components/MonitorView.vue";
  import ComposeMessage from "./components/ComposeMessage.vue";
  //import AccountService from "./services/AccountService";
  export default {
    components: { SideNav, MonitorView },
    mounted() {
      this.handleChange();
    },
    methods: {
      openComposeDialog() {
        this.$dialog.open(ComposeMessage, {
          data: {
            content: null,
          },
          props: {
            modal: true,
            header: "Netzwerkverkehr auslösen",
            style: {
              width: "50vw",
            },
            breakpoints: {
              "960px": "75vw",
              "640px": "90vw",
            },
          },
        });
      },
      handleChange(e) {
        let r = document.getElementById("mainAllJ");
        let m = document.getElementById("MonitorView_Component");
        if (!r) return;
        let side = {
          open: false,
          isPinned: false,
        };
        if (e == undefined) e = side.open;
        //console.log(side);

        if (!side.isPinned) {
          r.style.paddingLeft = "0px";

          //console.log("side is not pinned");
        }

        if (e == true) {
          m.style.paddingLeft = "50px";
        } else {
          m.style.paddingLeft = "250px";
        }
        if (e == true && side.isPinned) {
          // console.log("enter e == true && side.isPinned");
          if (window.innerWidth < 500) {
            console.log("0 pl");

            r.style.paddingLeft = "0px";
          } else {
            r.style.paddingLeft = "250px";
            console.log("250 pl");
          }
        }

        if (e == false && side.isPinned) {
          r.style.paddingLeft = "50px";
          m.style.paddingLeft = "50px";
          //console.log("50 pl");
        }
        // Get the computed margin-left of .MonitorView_container
      },
    },
    watch: {},
    computed: {},
  };
</script>
<style>
  #btn_compose {
    position: fixed;
    /* z-index: 9999; */
    right: 20px;
    bottom: 200px;
  }
  #mainAllJ {
    transition: all 0.5s;
    padding-left: 250px;
    height: 100vh;
    width: 100%;
    background-color: rgba(138, 138, 255, 0.144);
  }

  #overlay_menu_profile {
    z-index: 9999 !important;
  }
  @media (max-width: 768px) {
    /* Styles for small screen sizes */
    #mainAllJ {
      padding-left: 0;
    }
  }
</style>
